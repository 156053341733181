const posts = [
  {
    "slug": "0",
    "title": "Departure",
    "path": "/posts/0.md"
  },
  {
    "slug": "1",
    "title": "Giorno 1 - Lima",
    "path": "/posts/1.md"
  },
  {
    "slug": "2",
    "title": "Giorno 2 - Paracas",
    "path": "/posts/2.md"
  },
  {
    "slug": "3",
    "title": "Giorno 3 - Ica",
    "path": "/posts/3.md"
  },
  {
    "slug": "4",
    "title": "Giorno 4 - Nasca",
    "path": "/posts/4.md"
  },
  {
    "slug": "5",
    "title": "Giorno 5 - Arequipa",
    "path": "/posts/5.md"
  },
  {
    "slug": "6",
    "title": "Giorno 6 - Ci vai a Chivay?",
    "path": "/posts/6.md"
  },
  {
    "slug": "7",
    "title": "Giorno 7 - Il Camion del Colca",
    "path": "/posts/7.md"
  },
  {
    "slug": "8",
    "title": "Giorno 8 - Il lago Chiticaca",
    "path": "/posts/8.md"
  },
  {
    "slug": "9",
    "title": "Giorno 9 - Tequila parte dos",
    "path": "/posts/9.md"
  },
  {
    "slug": "10",
    "title": "Giorno 10 - Esodo",
    "path": "/posts/10.md"
  },
  {
    "slug": "11",
    "title": "Giorno 11 - Sinceramente non lo so",
    "path": "/posts/11.md"
  },
  {
    "slug": "12",
    "title": "Giorno 12 - Machu Pi(ka)cchu",
    "path": "/posts/12.md"
  },
  {
    "slug": "13",
    "title": "Giorno 13 - L'ombelico del mondo",
    "path": "/posts/13.md"
  },
  {
    "slug": "14",
    "title": "Giorno 14 - Guayaquil",
    "path": "/posts/14.md"
  },
  {
    "slug": "15",
    "title": "Giorno 15 - Esodo parte dos",
    "path": "/posts/15.md"
  },
  {
    "slug": "16",
    "title": "Giorno 16 - Il paradiso di Darwin",
    "path": "/posts/16.md"
  },
  {
    "slug": "17",
    "title": "Giorno 17 - Tintoreras",
    "path": "/posts/17.md"
  },
  {
    "slug": "18",
    "title": "Giorno 18 - Santa Cruz",
    "path": "/posts/18.md"
  },
  {
    "slug": "19",
    "title": "Giorno 19 - Verso Quito",
    "path": "/posts/19.md"
  },
  {
    "slug": "20",
    "title": "Giorno 20 - That's all folks.",
    "path": "/posts/20.md"
  }
];

export default posts;